function getIEVersion() {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));

  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./))
    return 11;

  else
    return 0; //It is not IE
}

var cbs;
jQuery(document).ready(function ($) {

	if (getIEVersion() == 11) {
		$('.foglalas-helyek-wrap').addClass('ie11');
	}

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$('.confirm').click(function(event) {
		return confirm("Biztos benne?");
	});

	$(".balmenu-toggle:visible").on("click", function(e){
		e.preventDefault();

		var $balmenu = $(".balmenu");

		if($balmenu.hasClass("open")) {
			$balmenu.removeClass("open");
			$(this).attr("aria-expanded", "false");
		} else {
			$balmenu.addClass("open");
			$(this).attr("aria-expanded", "true");
		}
	});

	$(".btn-uj-uzenet").on("click", function(e){
		e.preventDefault();
		$(".uj-uzenet-form").stop().toggle(200);
	});

	$(".balmenu-munkanaptar-datepicker").each(function(){
		var $naptardiv = $(this);
		$naptardiv.datetimepicker({
			language: $("html").attr("lang"),
			weekStart: 1,
			todayHighlight: 0,
			startView: 2,
			minView: 2,
			maxView: 2,
			format: 'yyyy-mm-dd hh:ii',
			onRender: function (date) {
				var m = String(date.getMonth() + 1);
				var d = String(date.getDate());
				var date_ymd = date.getFullYear() + '-' + (m.length == 1 ? "0" : "") + m + '-' + (d.length == 1 ? "0" : "") + d;

				if (typeof esemenyek_sajat == "object" && esemenyek_sajat.hasOwnProperty(date_ymd)) {
					return ' highlight-sajat';
				}

				if (typeof esemenyek == "object" && esemenyek.hasOwnProperty(date_ymd)) {
					return ' highlight';
				}

				return '';
			}
		}).on('changeDate', function(ev){
	        document.location = "/esemenyek/" + ev.date.getFullYear() + "-" + (ev.date.getMonth()+1) + "-" + ev.date.getDate();
	    });
	});

	$(".probateremfoglalas-datepicker").each(function(){
		var $naptardiv = $(this),
			aktiv_datum = $naptardiv.data("selected");

		$naptardiv.datetimepicker({
			language: $("html").attr("lang"),
			weekStart: 1,
			todayHighlight: 0,
			startView: 2,
			minView: 2,
			maxView: 2,
			format: 'yyyy-mm-dd hh:ii',
			onRender: function (date) {
				if(!aktiv_datum) return '';

				var m = String(date.getMonth() + 1);
				var d = String(date.getDate());
				var date_ymd = date.getFullYear() + '-' + (m.length == 1 ? "0" : "") + m + '-' + (d.length == 1 ? "0" : "") + d;

				if (date_ymd == aktiv_datum) return ' highlight-sajat';

				return '';
			}
		}).on('changeDate', function(ev){
			var m = String(ev.date.getMonth() + 1);
			var d = String(ev.date.getDate());
	        document.location = "/probaterem-foglalas/" + ev.date.getFullYear() + '-' + (m.length == 1 ? "0" : "") + m + '-' + (d.length == 1 ? "0" : "") + d;
	    });
	});

	$('.dt-timepicker').datetimepicker({
		language: 'hu',
		weekStart: 1,
		autoclose: 1,
		todayHighlight: 1,
		startView: 2,
		minView: 0,
		maxView: 4,
		format: 'yyyy-mm-dd hh:ii'
	});

	$('.probaterem-naptar').each(function () {
		var $this = $(this),
			$modal = $($this.data('modal'));

		var $closeButton = $this.siblings('.torol');

		$this.fullCalendar({
			allDaySlot: false,
			firstDay: 1,
			titleFormat: 'YYYY MMMM D',
			locale: 'hu',
			businessHours: {
				dow: [1, 2, 3, 4, 5, 6, 7],
				start: '08:00',
				end: '20:00',
			},
			defaultView: 'agendaWeek',
			editable: false,
			eventOverlap: false,
			eventClick: function (calEvent, e) {
				if (calEvent.remote && ! $(e.target).is('.glyphicon-trash')) {
					var params = {
						terem_id: $('#terem_id').val()
					};
					$modal.modal({
						remote: calEvent.remote + "?" + jQuery.param(params)
					}).modal('show');
				}
				return false;
			},
			eventRender: function (event, element) {
				if (event.editable && event.delete) {
					element.find('.fc-content').prepend($closeButton.clone(true).removeClass('hidden').attr('href', event.delete));
				}
			},
			events: {
				url: $this.data('url'),
				type: 'GET',
				data: function () {
					return {
						type: $this.data('type')
					};
				}
			},
			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,agendaWeek,agendaDay,listWeek'
			},
			locale: 'hu',
			minTime: '08:00',
			maxTime: '20:00',
			navLinks: true,
			select: function (start, end) {
				var params = {
					kezdoido: start.format('YYYY-MM-DD HH:mm:ss'),
					zaroido: end.format('YYYY-MM-DD HH:mm:ss'),
					terem_id: $('#terem_id').val()
				};
				$modal.modal({
					remote: $this.data('create-url') + '?' + jQuery.param(params)
				}).modal('show');
			},
			selectable: true,
			selectAllow: function (selectInfo) {
				return selectInfo.start.isSame(selectInfo.end, 'day');
			},
			selectOverlap: false,
			timezone: 'Europe/Budapest',
			eventStartEditable: false,
			eventDurationEditable: false
		});
	});

	$('.autosubmit').on('change','input,select', function (e) {
		e.delegateTarget.submit();
	});

	cbs = {
		callbackHandler: function (cb, resp, $elem) {
			$.each(cb.split(',') || [], function (i, c) {
				if (c && $.isFunction(cbs[c])) {
					cbs[c](resp, $elem);
				}
			});
		},
		naptar_esemeny_delete: function (resp, $elem) {
			console.log(resp);
			if (resp.status == 'success') {
				var $modal = $elem.parents('.modal');
				if ($modal.length) {
					$modal.modal('hide');
					$($modal.data('naptar')).fullCalendar('refetchEvents');
				} else {
					console.log($elem.parents('.fc'));
					$($elem.parents('.fc')).fullCalendar('refetchEvents');
				}
			}
		},
	};

	// törlés gomb
	$(document).on('click', '.torol', function(e) {
		e.preventDefault();
		var $this = $(this);
		if (confirm($this.data('confirm') || 'Biztos benne?')) {
			$.ajax({
				url: $this.attr('href'),
				method: 'DELETE',
				success: function(resp) {
					cbs.callbackHandler($this.data('callback'), resp, $this);
				}
			});
		}
	});

	$('.jcrop').each(function() {
		var $this = $(this),
			name = $this.data('name') || '_kivagasok',
			aspectRatio = $this.data('aspect-ratio') || 1,
			selection = $this.data('selection'),
			$input = $('<input type="hidden" name="' + name + '[' + aspectRatio + ']">');

		$this.parent().append($input);
		setTimeout(function() { // hack!
			$this.Jcrop({
				trueSize: [
					$this.get(0).naturalWidth,
					$this.get(0).naturalHeight
				],
				keySupport: false,
				aspectRatio: aspectRatio,
				onChange: function(c) {
					$input.val(JSON.stringify(c));
				},
				onSelect: function(c) {
					$input.val(JSON.stringify(c));
				},
				setSelect: [
					selection.x,
					selection.y,
					selection.x2,
					selection.y2
				],
				boxWidth: $this.width(),
				bgColor: ''
			});
		}, 400);
	});

	new Clipboard("#share_link_button");

	$('#honap_valaszto, .honap_valaszto').change(function(event) {
		$(this).parents('form').submit();
	});

	$('.szolamvalaszto select').change(function(event) {
		if ($("#szolam_honap").val() && $('#szolam_szolam').val()) {
			window.open (
					"http://pfz.projectmanager.hu/zenekari-munkarend/szolambeosztas.php"+
					"?Tol="+$("#szolam_honap").val()+"-01&Szolam="+($('#szolam_szolam').val())+"&UresNapokIs=1&ZeneszID2=" + $("#szolam_user_id").val(),
					"_blank"
			);
		}
	});

	$(".tavolmaradasi-beszelgetes").each(function(index, el) {
		$(this).scrollTop(this.scrollHeight);
	});

	$('input[name=egesz_nap]').change(function(event) {
		if ($(this).is(":checked")) {
			$('#terem_' + $(this).data('terem')).find('.probateremfoglalas-checkbox').prop('checked',true);
		}else{
			$('#terem_' + $(this).data('terem')).find('.probateremfoglalas-checkbox').prop('checked', false);
		}
	});

	$('.btn-fb').click(function(event) {
		FB.ui(
		{
		  method: 'feed',
		  link: $(this).attr('href')
		}, function(response){});
		return false;
	});

	$('article.post').linkify({
	    target: "_blank"
	});

	$('#file_input').on('change', "input[type=file]", function () {
		if($(this).val() != '') {
			$(this).clone().appendTo('#file_input').val('');
		}
	});

});

function del_confirm() {
	confirm("Biztos benne?");
}
